// root
// - set device
// - set window size
<template>
  <div class="root-loading">
    <v-layout
      v-if="!!loading"
      justify-center
      align-center
      style="position: fixed;top: 0;bottom: 0;left: 0;right: 0;z-index: 3000;background: black;opacity: .46"
    >
      <div>
        <v-progress-circular
          v-if="!!loading.trim()"
          :width="5"
          color="white"
          indeterminate
          :size="30"
        ></v-progress-circular>
        <div
          class="white--text"
          :style="{
            'margin-top': '12px',
            'font-size': '14px'
          }"
        >
          {{ loading }}
        </div>
      </div>
    </v-layout>
      <v-app class="app" v-cloak>
        <v-fade-transition mode="out-in">
          <!-- ROUTERVIEW -->
          <my-msal>
            <router-view></router-view>
          </my-msal>
        </v-fade-transition>
      </v-app>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import fadeInOut from '@/components/fade-in-out'
import MyMsal from './my-msal/index.vue'

export default {
  computed: {
    ...mapState('app', {
      loading: state => state.loading
    })
  },
  components: {
    // fadeInOut,
    MyMsal,
  },
  methods: {
    /**
     * vuex - actions
     */
    ...mapActions('app', [
      'setDevice',
      'setLoading',
      'setWindowSize'
    ]),
    setDeviceByAgent () {
      let userAgentInfo = navigator.userAgent || ''
      this.setDevice(userAgentInfo)
    }
  },
  created () {
    this.$eventBus.$on('loading', this.setLoading)
    /**
     * bind window size
     */
    this.setDeviceByAgent()
    this.setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    window.onresize = () => {
      this.setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    }
  },
  mounted() {
  },
  beforeDestroy() {
    this.$eventBus.$off('loading', this.setLoading)
  }
}
</script>

<style lang="sass">
  .root-loading
    .v-card
      box-shadow: none
    

    .v-dialog
      box-shadow: none
    
  
  .flex-column
    flex-direction: column
	

  body
    font-family: "Roboto"
    font-size: 14px
    background: #fafafa
    text-rendering: geometricPrecision
</style>
